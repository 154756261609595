import React from 'react';
import { Helmet } from 'react-helmet';
import './sponsorship.scss';
import Layout from '../components/Layout/Layout';

const Sponsorship = () => {
  return (
    <Layout>
      <Helmet>
        <script
          type="text/javascript"
          id="embed_js"
          src="https://apps.letthemshineusa.org/html/common/themes/embeddedArreva.js?Z1pFL3VvNUhCNHQzbytlY1hYeEdoOGVvN3RmaHl3Q08yTzYxVDFndEN1ZVdaOWNOYkZNVk1jeG54UEhaR2M0ek82UzUzZElieklod2N0RjEyWThDR3FrRG9HaDBndk4xWkRuUTJlVkJoNmRPdzZQWjJNSzBLTVFJd3FCdWprbS8="
        ></script>
      </Helmet>
      <div className="sponsorship">
        <div id="arreva-div"></div>
      </div>
    </Layout>
  );
};

export default Sponsorship;
